export const templates = [
  {
    value:"To speak with someone regarding your benefits case, please contact the Kansas DCF office at 1-888-369-4777.",
    spanishValue:"Para hablar con alguien sobre su caso de beneficios, comuníquese con la oficina del DCF de Kansas al 1-888-369-4777.",
    label:"Benefits: To speak with someone regarding your benefits case, please contact the Kansas DCF office at 1-888-369-4777."

  },
  {
    value:"To reach Child Support Services, please call the Child Support Call Center toll free at 1-888-757-2445.",
    spanishValue:"Para comunicarse con los Servicios de manutención infantil, llame gratis al Centro de llamadas de manutención infantil al 1-888-757-2445.",
    label:"Child Support Services: ​To reach Child Support Services, please call the Child Support Call Center toll free at 1-888-757-2445."

  },
  {
    value:"Please review the Parent-Provider Partnership Handbook linked below for helpful information regarding DCF child care benefits. http://childcareinkansas.com/parent-provider-handbook.",
    spanishValue:"Revise el Manual de asociación entre padres y proveedores vinculado a continuación para obtener información útil sobre los beneficios de cuidado infantil del DCF. http://childcareinkansas.com/parent-provider-handbook.",
    label:"Parent Provider Partnership Handbook: Please review the Parent-Provider Partnership Handbook linked below for helpful information regarding DCF child care benefits. http://childcareinkansas.com/parent-provider-handbook."
  },
  {
    value:"​SUN Bucks is a program to help families buy food for their school-aged children during the summer. For more regarding the program please visit SUNBucks.dcf.ks.gov. Families that have not received a preapproval letter can apply at dcfapp.kees.ks.gov.",
    spanishValue:"SUN Bucks es un programa para ayudar a las familias a comprar alimentos para sus hijos en edad escolar durante el verano. Para obtener más información sobre el programa, visite SUNBucks.dcf.ks.gov. Las familias que no hayan recibido una carta de preaprobación pueden presentar su solicitud en dcfapp.kees.ks.gov.",
    label:"SUN Bucks: SUN Bucks is a program to help families buy food for their school-aged children during the summer. For more regarding the program please visit SUNBucks.dcf.ks.gov. Families that have not received a preapproval letter can apply at dcfapp.kees.ks.gov."

  },
  {
    value:"To find additional helpful resources and support services near you,  please visit  https://www.211.org/ or https://1800childrenks.org/. If you or someone you know needs emotional support or crisis prevention, please call or text 988. You may also visit https://988lifeline.org/ or https://acmhck.org/ for further information.",
    spanishValue:"Para encontrar recursos útiles adicionales y servicios de apoyo cerca de usted, por favor visite https://www.211.org/ o https://1800childrenks.org/. Si usted o alguien que usted conozca necesita apoyo emocional o prevención de crisis, llame o envíe un mensaje de texto al 988. También puede visitar https://988lifeline.org/ o https://acmhck.org/ para obtener mayor información.",
    label:"Support services: To find additional helpful resources and support services near you,  please visit  https://www.211.org/ or https://1800childrenks.org/. If you or someone you know needs emotional support or crisis prevention, please call or text 988. You may also visit https://988lifeline.org/ or https://acmhck.org/ for further information. "

  },
  {
    value: "The best way to apply is to visit our website at www.dcf.ks.gov, where you can find instructions to apply online.",
    spanishValue:"La mejor manera de aplicar es visitar nuestra página web www.dcf.ks.gov, donde puede encontrar instrucciones para aplicar en línea.",
    label: "Apply: The best way to apply is to visit our website at www.dcf.ks.gov, where you can find instructions to apply online.",
  },
  {
    value: "This is the DCF attempting to reach you regarding your work requirements. Please see the following link for more information: https://content.dcf.ks.gov/EES/KEESM/Appendix/E-26%20-%20Consolidated%20Work%20Requirements%20(English).pdf",
    spanishValue:"Este es DCF tratando de contactarlo con respecto a sus requisitos de trabajo. Por favor, consulte el siguiente enlace para obtener más información: https://content.dcf.ks.gov/EES/KEESM/Appendix/E-26%20-%20Consolidated%20Work%20Requirements%20(English).pdf",
    label: "Work Requirements: This is the DCF attempting to reach you regarding your work requirements. Please see the following link for more information: https://content.dcf.ks.gov/EES/KEESM/Appendix/E-26%20-%20Consolidated%20Work%20Requirements%20(English).pdf ",
  },
  {
    value: "The Food Assistance Program provides qualifying low income households with food benefits, access to a healthy diet and education on food preparation and nutrition.",
    spanishValue:"El Programa de Asistencia Alimentaria brinda beneficios alimentarios, acceso a dietas saludables y educación en nutrición y preparación de comidas a las familias de bajos ingresos que califiquen.",
    label: "Food Assistance: The Food Assistance Program provides qualifying low income households with food benefits, access to a healthy diet and education on food preparation and nutrition.",
  },
  {
    value: "Cash assistance is also known as Temporary Assistance for Needy Families or TANF. The amount of cash benefit provided depends upon the family family`s income and the county where the family lives.",
    spanishValue:"La ayuda en efectivo es también conocida como Asistencia Temporal para familias en necesidad o TANF, por sus siglas en inglés. La cantidad de beneficios en efectivo que se otorga depende del ingreso familiar y el condado en el que vive la familia.",
    label: "Cash Assistance: Cash assistance is also known as Temporary Assistance for Needy Families or TANF. The amount of cash benefit provided depends upon the family family`s income and the county where the family lives.",
  },
  {
    value: "The Child Care Subsidy Program helps pay for childcare costs. It helps many types of families.",
    spanishValue:"El Programa de Subsidio de Guardería ayuda a pagar los costos de guardería. Ayuda a muchos tipos de familias.",
    label: "Child Care: The Child Care Subsidy Program helps pay for childcare costs. It helps many types of families.",
  },
  {
    value: "The Low-Income Energy Assistance Program or LIEAP helps eligible households pay a portion of their home energy costs by providing a one-time per year benefit. You can now apply for the Low Income Energy Assistance Program (LIEAP) for help with your home heating costs. Applications will be accepted November 18, 2024, through 5pm on Monday, March 31, 2025. Please visit our website at www.dcf.ks.gov and click apply for services found in the upper right corner.",
    spanishValue:"El Programa de Asistencia de Energía para bajos ingresos o LIEAP, por sus siglas en inglés, ayuda a las familias elegibles a pagar una porción de sus costos de energía de su hogar, brindando un beneficio de una vez al año. Ahora puede aplicar al Programa de Asistencia de Energía para Personas de Bajos Ingresos (LIEAP, por sus siglas en inglés) para obtener ayuda con los costos de calefacción de su hogar. Se aceptarán solicitudes desde el 18 de noviembre de 2024 hasta las 5 p.m. del lunes 31 de marzo de 2025. Visite nuestra página web en www.dcf.ks.gov y haga clic en solicitar servicios que encontrará en la esquina superior derecha.",
    label: "LIEAP: The Low-Income Energy Assistance Program or LIEAP helps eligible households pay a portion of their home energy costs by providing a one-time per year benefit. You can now apply for the Low Income Energy Assistance Program (LIEAP) for help with your home heating costs. Applications will be accepted November 18, 2024, through 5pm on Monday, March 31, 2025. Please visit our website at www.dcf.ks.gov and click apply for services found in the upper right corner.",
  },
  {
    value: "For information on Foster Care Child Care (FCCC) unit, please email DCF.FosterCareCC@ks.gov. You may also call 785-368-8594 or visit the website https://childcareinkansas.com/resource/foster-care-child-care/.",
    spanishValue:"Para información acerca de la Unidad de Guardería de Adopción Temporal (Foster Care) (FCCC, por sus siglas en inglés), por favor, envíe un correo electrónico a DCF.FosterCareCC@ks.gov. También puede llamar al 785-368-8594 o visitar la página web https://childcareinkansas.com/resource/foster-care-child-care/.",
    label: "FCCC: For information on Foster Care Child Care (FCCC) unit, please email DCF.FosterCareCC@ks.gov. You may also call 785-368-8594 or visit the website https://childcareinkansas.com/resource/foster-care-child-care/.",
  },
  {
    value: "SNAP-Ed provides interactive nutrition and physical activity education for youths, adults, families, and seniors in our community. To learn more or find a class near you: Call 1-785-532-2898.",
    spanishValue:"SNAP-Ed proporciona educación interactiva sobre nutrición y actividad física para jóvenes, adultos, familias y adultos mayores (seniors) en nuestra comunidad. Para mayor información o para encontrar una clase cercana a usted, llame al 1-785-532-2898.",
    label: "SNAP: SNAP-Ed provides interactive nutrition and physical activity education for youths, adults, families, and seniors in our community. To learn more or find a class near you: Call 1-785-532-2898.",
  },
  {
    value: "For assistance with your Kansas Benefit Card, please call: 1-800-997-6666.",
    spanishValue:"Para ayuda con su Tarjeta de beneficios Kansas Benefit Card, por favor llame al 1-800-997-6666.",
    label: "Benefit: For assistance with your Kansas Benefit Card, please call: 1-800-997-6666.",
  },
  {
    value: "For assistance with KanCare, you can visit their website at https://www.kancare.ks.gov/ or call 800-792-4884.",
    spanishValue:"Para ayuda con KanCare, puede visitar su página web https://www.kancare.ks.gov/ o llamar al 1-800-792-4884. ",
    label: "Kan Care: For assistance with KanCare, you can visit their website at https://www.kancare.ks.gov/ or call 800-792-4884.",
  },
  {
    value: "Work Programs Online Orientation: http://content.dcf.ks.gov/SD/WPOHTML/.",
    spanishValue:"Orientación en línea para los Programas de Trabajo: http://content.dcf.ks.gov/SD/WPOHTML/",
    label: "Orientation: Work Programs Online Orientation: http://content.dcf.ks.gov/SD/WPOHTML/.",
  },
  {
    value:"The best way to apply for Unemployment or get in touch with the Kansas Department of Labor is at their website at www.getkansasbenefits.gov.",
    spanishValue:"La mejor manera de aplicar para desempleo o comunicarse con el Departamento de Trabajo de Kansas es mediante su página web www.getkansasbenefits.gov",
    label:"Unemployment: The best way to apply for Unemployment or get in touch with the Kansas Department of Labor is at their website at www.getkansasbenefits.gov."
  },
  {
    value:"If you completed an application and have not received a notice, please allow 30 days for your application to be processed. If additional information is needed, you will be contacted by mail or through the Self-Service Message Center if you have opted for electronic notices. Please be sure to open all mail received from DCF.",
    spanishValue:"Si ha hecho una aplicación y no ha recibido un aviso, por favor espere 30 días para que su aplicación sea procesada. Si se necesitase información adicional, se le avisará por correo o mediante el Centro de auto-servicios de mensajería, si usted ha optado por recibir notificaciones electrónicas. Por favor, asegúrese de abrir todos los correos que reciba del DCF. ",
    label:"Apply: If you completed an application and have not received a notice, please allow 30 days for your application to be processed. If additional information is needed, you will be contacted by mail or through the Self-Service Message Center if you have opted for electronic notices. Please be sure to open all mail received from DCF."
  },
  {
    value: "You can send any additional information to Dcf.Atchisonees@ks.gov.",
    spanishValue:"Puede enviar cualquier información adicional a: Dcf.Atchisonees@ks.gov.",
    label: "Additional Information: You can send any additional information to Dcf.Atchisonees@ks.gov.",
  },
  {
    value: "You can send any additional information to Dcf.Chanuteees@ks.gov.",
    spanishValue:"Puede enviar cualquier información adicional a: Dcf.Chanuteees@ks.gov.",
    label: "Additional Information: You can send any additional information to Dcf.Chanuteees@ks.gov.",
  },
  {
    value: "You can send any additional information to Dcf.Colbyees@ks.gov.",
    spanishValue:"Puede enviar cualquier información adicional a: Dcf.Colbyees@ks.gov.",
    label: "Additional Information: You can send any additional information to Dcf.Colbyees@ks.gov.",
  },
  {
    value: "You can send any additional information to Dcf.Columbusees@ks.gov.",
    spanishValue:"Puede enviar cualquier información adicional a: Dcf.Columbusees@ks.gov.",
    label: "Additional Information: You can send any additional information to Dcf.Columbusees@ks.gov.",
  },
  {
    value: "You can send any additional information to Dcf.Concordiaees@ks.gov.",
    spanishValue:"Puede enviar cualquier información adicional a: Dcf.Concordiaees@ks.gov.",
    label: "Additional Information: You can send any additional information to Dcf.Concordiaees@ks.gov.",
  },
  {
    value: "You can send any additional information to Dcf.DodgeCityees@ks.gov.",
    spanishValue:"Puede enviar cualquier información adicional a: Dcf.DodgeCityees@ks.gov.",
    label: "Additional Information: You can send any additional information to Dcf.DodgeCityees@ks.gov.",
  },
  {
    value: "You can send any additional information to Dcf.ElDoradoees@ks.gov.",
    spanishValue:"Puede enviar cualquier información adicional a: Dcf.ElDoradoees@ks.gov.",
    label: "Additional Information: You can send any additional information to Dcf.ElDoradoees@ks.gov.",
  },
  {
    value: "You can send any additional information to Dcf.Emporiaees@ks.gov.",
    spanishValue:"Puede enviar cualquier información adicional a: Dcf.Emporiaees@ks.gov.",
    label: "Additional Information: You can send any additional information to Dcf.Emporiaees@ks.gov.",
  },
  {
    value: "You can send any additional information to DCF.FtScottEES@ks.gov.",
    spanishValue:"Puede enviar cualquier información adicional a: DCF.FtScottEES@ks.gov.",
    label: "Additional Information: You can send any additional information to DCF.FtScottEES@ks.gov.",
  },
  {
    value: "You can send any additional information to Dcf.GardenCityees@ks.gov.",
    spanishValue:"Puede enviar cualquier información adicional a: Dcf.GardenCityees@ks.gov.",
    label: "Additional Information: You can send any additional information to Dcf.GardenCityees@ks.gov.",
  },
  {
    value: "You can send any additional information to Dcf.Goodlandees@ks.gov.",
    spanishValue:"Puede enviar cualquier información adicional a: Dcf.Goodlandees@ks.gov.",
    label: "Additional Information: You can send any additional information to Dcf.Goodlandees@ks.gov.",
  },
  {
    value: "You can send any additional information to Dcf.GreatBendees@ks.gov.",
    spanishValue:"Puede enviar cualquier información adicional a: Dcf.GreatBendees@ks.gov.",
    label: "Additional Information: You can send any additional information to Dcf.GreatBendees@ks.gov.",
  },
  {
    value: "You can send any additional information to Dcf.Haysees@ks.gov.",
    spanishValue:"Puede enviar cualquier información adicional a: Dcf.Haysees@ks.gov.",
    label: "Additional Information: You can send any additional information to Dcf.Haysees@ks.gov.",
  },
  {
    value: "You can send any additional information to Dcf.Hiawathaees@ks.gov.",
    spanishValue:"Puede enviar cualquier información adicional a: Dcf.Hiawathaees@ks.gov.",
    label: "Additional Information: You can send any additional information to Dcf.Hiawathaees@ks.gov.",
  },
  {
    value: "You can send any additional information to Dcf.Hutchinsonees@ks.gov.",
    spanishValue:"Puede enviar cualquier información adicional a: Dcf.Hutchinsonees@ks.gov.",
    label: "Additional Information: You can send any additional information to Dcf.Hutchinsonees@ks.gov.",
  },
  {
    value: "You can send any additional information to Dcf.Independenceees@ks.gov.",
    spanishValue:"Puede enviar cualquier información adicional a: Dcf.Independenceees@ks.gov.",
    label: "Additional Information: You can send any additional information to Dcf.Independenceees@ks.gov.",
  },
  {
    value: "You can send any additional information to Dcf.JunctionCityees@ks.gov.",
    spanishValue:"Puede enviar cualquier información adicional a: Dcf.JunctionCityees@ks.gov.",
    label: "Additional Information: You can send any additional information to Dcf.JunctionCityees@ks.gov.",
  },
  {
    value: "You can send any additional information to Dcf.KansasCityees@ks.gov.",
    spanishValue:"Puede enviar cualquier información adicional a: Dcf.KansasCityees@ks.gov.",
    label: "Additional Information: You can send any additional information to Dcf.KansasCityees@ks.gov.",
  },
  {
    value: "You can send any additional information to Dcf.Lawrenceees@ks.gov.",
    spanishValue:"Puede enviar cualquier información adicional a: Dcf.Lawrenceees@ks.gov.",
    label: "Additional Information: You can send any additional information to Dcf.Lawrenceees@ks.gov.",
  },
  {
    value: "You can send any additional information to Dcf.Leavenworthees@ks.gov.",
    spanishValue:"Puede enviar cualquier información adicional a: Dcf.Leavenworthees@ks.gov.",
    label: "Additional Information: You can send any additional information to Dcf.Leavenworthees@ks.gov.",
  },
  {
    value: "You can send any additional information to Dcf.Liberalees@ks.gov.",
    spanishValue:"Puede enviar cualquier información adicional a: Dcf.Liberalees@ks.gov.",
    label: "Additional Information: You can send any additional information to Dcf.Liberalees@ks.gov.",
  },
  {
    value: "You can send any additional information to Dcf.Manhattanees@ks.gov.",
    spanishValue:"Puede enviar cualquier información adicional a: Dcf.Manhattanees@ks.gov.",
    label: "Additional Information: You can send any additional information to Dcf.Manhattanees@ks.gov.",
  },
  {
    value: "You can send any additional information to Dcf.Marysvilleees@ks.gov.",
    spanishValue:"Puede enviar cualquier información adicional a: Dcf.Marysvilleees@ks.gov.",
    label: "Additional Information: You can send any additional information to Dcf.Marysvilleees@ks.gov.",
  },
  {
    value: "You can send any additional information to Dcf.McPhersonees@ks.gov.",
    spanishValue:"Puede enviar cualquier información adicional a: Dcf.McPhersonees@ks.gov.",
    label: "Additional Information: You can send any additional information to Dcf.McPhersonees@ks.gov.",
  },
  {
    value: "You can send any additional information to Dcf.Newtonees@ks.gov.",
    spanishValue:"Puede enviar cualquier información adicional a: Dcf.Newtonees@ks.gov.",
    label: "Additional Information: You can send any additional information to Dcf.Newtonees@ks.gov.",
  },
  {
    value: "You can send any additional information to Dcf.Osawatomieees@ks.gov.",
    spanishValue:"Puede enviar cualquier información adicional a: Dcf.Osawatomieees@ks.gov.",
    label: "Additional Information: You can send any additional information to Dcf.Osawatomieees@ks.gov.",
  },
  {
    value: "You can send any additional information to Dcf.Ottawaees@ks.gov.",
    spanishValue:"Puede enviar cualquier información adicional a: Dcf.Ottawaees@ks.gov.",
    label: "Additional Information: You can send any additional information to Dcf.Ottawaees@ks.gov.",
  },
  {
    value: "You can send any additional information to Dcf.OverlandParkees@ks.gov.",
    spanishValue:"Puede enviar cualquier información adicional a: Dcf.OverlandParkees@ks.gov.",
    label: "Additional Information: You can send any additional information to Dcf.OverlandParkees@ks.gov.",
  },
  {
    value: "You can send any additional information to Dcf.Parsonsees@ks.gov.",
    spanishValue:"Puede enviar cualquier información adicional a: Dcf.Parsonsees@ks.gov.",
    label: "Additional Information: You can send any additional information to Dcf.Parsonsees@ks.gov.",
  },
  {
    value: "You can send any additional information to Dcf.Phillipsburgees@ks.gov.",
    spanishValue:"Puede enviar cualquier información adicional a: Dcf.Phillipsburgees@ks.gov.",
    label: "Additional Information: You can send any additional information to Dcf.Phillipsburgees@ks.gov.",
  },
  {
    value: "You can send any additional information to Dcf.Pittsburgees@ks.gov.",
    spanishValue:"Puede enviar cualquier información adicional a: Dcf.Pittsburgees@ks.gov.",
    label: "Additional Information: You can send any additional information to Dcf.Pittsburgees@ks.gov.",
  },
  {
    value: "You can send any additional information to Dcf.Prattees@ks.gov.",
    spanishValue:"Puede enviar cualquier información adicional a: Dcf.Prattees@ks.gov.",
    label: "Additional Information: You can send any additional information to Dcf.Prattees@ks.gov.",
  },
  {
    value: "You can send any additional information to Dcf.Salinaees@ks.gov.",
    spanishValue:"Puede enviar cualquier información adicional a: Dcf.Salinaees@ks.gov.",
    label: "Additional Information: You can send any additional information to Dcf.Salinaees@ks.gov.",
  },
  {
    value: "You can send any additional information to Dcf.Topekaees@ks.gov.",
    spanishValue:"Puede enviar cualquier información adicional a: Dcf.Topekaees@ks.gov.",
    label: "Additional Information: You can send any additional information to Dcf.Topekaees@ks.gov.",
  },
  {
    value: "You can send any additional information to Dcf.Wichitaees@ks.gov.",
    spanishValue:"Puede enviar cualquier información adicional a: Dcf.Wichitaees@ks.gov.",
    label: "Additional Information: You can send any additional information to Dcf.Wichitaees@ks.gov.",
  },
  {
    value: "You can send any additional information to Dcf.Winfieldees@ks.gov.",
    spanishValue:"Puede enviar cualquier información adicional a: Dcf.Winfieldees@ks.gov.",
    label: "Additional Information: You can send any additional information to Dcf.Winfieldees@ks.gov.",
  }
];
